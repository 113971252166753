import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from '../sections/customer-stories/Testimonial'
import Hero from "~sections/Experts/Hero"
import Scale from "~sections/Experts/Sections/scale"
import Uncover from "~sections/Experts/Sections/uncover"
import Research from "~sections/Experts/Sections/research"
import Discover from "~sections/Experts/Sections/discover"
import Navigator from "~sections/Experts/Sections/navigator"
import GradTwo from "~sections/Experts/Sections/grad"
import { Helmet } from "react-helmet";
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Entrepreneurs() {
  return (
    <>
    <Helmet>
        <title>Entrepreneurs</title>
        <meta name="description" content="Amplify your message using a We-Connect outreach campaign. Establish yourself as a thought leader & start fueling your growth by signing up today!"/>
        <meta property="og:title" content="Entrepreneurs & Experts"/>
        <meta property="og:description" content="Amplify your message using a We-Connect outreach campaign. Establish yourself as a thought leader & start fueling your growth by signing up today!  "/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/entrepreneurs"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="Entrepreneurs & Experts "/>
        <meta property="twitter:description" content="Amplify your message using a We-Connect outreach campaign. Establish yourself as a thought leader & start fueling your growth by signing up today! "/>
        <meta property="twitter:image" content="https://we-connect.io/entrepreneurs.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <Hero />
      <Scale />
      <Uncover />
      <Research />
      <Discover />
      <Navigator />
      <GradTwo />
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
