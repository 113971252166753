import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from '../Styles/styleTwo'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/media/Entrepreneurs/06.svg";

export default function Navigator({}){

return(
<Content backgroundColor="#F3F4F6">
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Maximize the reach of your content"/> </Content.Title>

          <Content.Text> <SuperTag value="Take charge of your impact and reach a broader audience effortlessly. Automate connection campaigns to scale your network with individuals genuinely interested in your content."/> </Content.Text>
          <Content.Button className="btn btn-primary text-white" target="_blank" to="//account.we-connect.io/signup">Get started now</Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}