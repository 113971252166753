import React from 'react'
import Content from '../Styles/styleOne'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/Entrepreneurs/05.svg";

export default function Discover({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" height={450} />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Build strong social proof"/> </Content.Title>

          <Content.Text> <SuperTag value="Your LinkedIn account is connected to your brand’s credibility. Strengthen your reputation and build trust with new clients through tailored outreach campaigns that establish your profile."/> </Content.Text>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}